import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d2ee94a0 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _7ce72eda = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _06c09cf5 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _1fd88380 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _ec85835e = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _ec94a32e = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _505a89fa = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _45bc6242 = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _70dae96b = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _549bdf43 = () => interopDefault(import('../pages/Reviews1111111.vue' /* webpackChunkName: "pages/Reviews1111111" */))
const _92ae9f94 = () => interopDefault(import('../pages/selector-car/index.vue' /* webpackChunkName: "pages/selector-car/index" */))
const _41503c2a = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _51e835df = () => interopDefault(import('../pages/test-drive/index.vue' /* webpackChunkName: "pages/test-drive/index" */))
const _ad601f94 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _193957db = () => interopDefault(import('../pages/Trade-in.vue' /* webpackChunkName: "pages/Trade-in" */))
const _7cd1dd96 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _3ece7095 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _64d619a3 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _621a2f80 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _4fbf362f = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _52f5ff4b = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _d2ee94a0,
    name: "About"
  }, {
    path: "/Banks",
    component: _7ce72eda,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _06c09cf5,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _1fd88380,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _ec85835e,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _ec94a32e,
    name: "Credit"
  }, {
    path: "/Favorites",
    component: _505a89fa,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _45bc6242,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _70dae96b,
    name: "Privacy"
  }, {
    path: "/Reviews1111111",
    component: _549bdf43,
    name: "Reviews1111111"
  }, {
    path: "/selector-car",
    component: _92ae9f94,
    name: "selector-car"
  }, {
    path: "/services",
    component: _41503c2a,
    name: "services"
  }, {
    path: "/test-drive",
    component: _51e835df,
    name: "test-drive"
  }, {
    path: "/Thanks",
    component: _ad601f94,
    name: "Thanks"
  }, {
    path: "/Trade-in",
    component: _193957db,
    name: "Trade-in"
  }, {
    path: "/Credit/:bank",
    component: _7cd1dd96,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _3ece7095,
    name: "index"
  }, {
    path: "/:category",
    component: _64d619a3,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _621a2f80,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _4fbf362f,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _52f5ff4b,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
