
export default {
  name: "HistoryModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    stories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiper: null
    };
  },
  computed: {
    isMobile() {
      return this.$device.isMobile;
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initSwiper();
        });
      }
    }
  },
  methods: {
    close() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
      }
      this.$emit("close");
    },
    async initSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }

      const { Swiper, Pagination, Autoplay } = await import("swiper");
      this.swiper = new Swiper(".history-modal__slider", {
        modules: [Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 16,
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      });
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }
};
