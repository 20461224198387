
import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
		}),
		closeModals() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		}
	},
	computed: {
		nav() {
			return [
				// {
				// 	title: 'НОВЫЕ АВТО',
				// 	link: '/new'
				// },
				{
					title: 'каталог',
					link: '/cars'
				},
				{
					title: 'автокредит',
					link: '/credit'
				},
				{
					title: 'trade-in',
					link: '/trade-in'
				},
				{
					title: 'рассрочка',
					link: '/installment'
				},
				{
					title: 'выкуп',
					link: '/buyout'
				},
				{
					title: 'тест-драйв',
					link: '/test-drive'
				},
				{
					title: 'подбор',
					link: '/selector-car'
				},
				{
					title: "банки-партнеры",
					link: "/banks"
				},
				{
					title: 'контакты',
					link: '/contacts'
				},
				{
					title: "об автосалоне",
					link: "/about"
				},
				{
					title: "услуги",
					link: "/services"
				},
			]
		},

		route() {
			return this.$route.path
		}
	},

}
