import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6c4b71f7&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/d/didisher2/ac-algaauto.ru/www/components/Header/header.vue').default,ModalWrap: require('/home/d/didisher2/ac-algaauto.ru/www/components/modal/modal-wrap.vue').default,ModalBenefits: require('/home/d/didisher2/ac-algaauto.ru/www/components/modal/modal-benefits.vue').default,ModalCallbackFixed: require('/home/d/didisher2/ac-algaauto.ru/www/components/modal/modal-callback-fixed.vue').default,ModalCookieMain: require('/home/d/didisher2/ac-algaauto.ru/www/components/modal/modal-cookie-main.vue').default,Footer: require('/home/d/didisher2/ac-algaauto.ru/www/components/Footer/footer.vue').default})
