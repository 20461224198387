
export default {
  name: "HistoryChip",

  props: {
    image: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    stories: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    openModal() {
      this.$emit("click", this.stories);
    }
  }
};
