
import seoTags from "@/mixins/seoTags";
// import LazyHydrate from 'vue-lazy-hydration';
export default {
	mixins: [seoTags],
	// 	components: {
	//     LazyHydrate,
	//   },
	mounted() {
		setTimeout(function () {
			window.scrollTo(0, -100);
		}, 1);
	},

	data() {
		return {
			foz: {
				img: require('~/assets/img/foz/foz.webp'),
				proposal: `Предложение ограничено`,
				title: "Проезд за наш счет",
				desc: "Компенсируем затраты на билеты и такси при покупке автомобиля"
			}
		}
	}

}
