
import HistoryChip from "./history-chip.vue";
import HistoryModal from "./history-modal.vue";

export default {
  name: "HistoryIndex",
  components: {
    HistoryChip,
    HistoryModal
  },
  data() {
    return {
      swiper: null,
      isModalOpen: false,
      selectedItem: null,
      selectedService: null,
      historyItems: [
        {
          image: require("~/assets/img/history/credit.png"),
          text: "Автокредит",
          stories: [
            {
              title: "Подберём кредит с подходящими для Вас условиями",
              description:
                "Сравним предложения разных банков по вашим запросам и выберем самое привлекательное.",
              image: require("~/assets/img/history/main/his-1.webp"),
              link: "/credit"
            },
            {
              title: "Заполните заявку на автокредит от 4.9%",
              description: "Получите моментальную скидку до 300 000₽",
              image: require("~/assets/img/history/main/his-2.webp"),
              link: "/credit"
            },
            // {
            //   title: "можно без взноса по двум документам",
            //   description:
            //     "Одобряем автокредит по паспорту и правам для граждан РФ",
            //   image: require("~/assets/img/history/main/his-3.webp"),
            //   link: "/credit"
            // }
          ]
        },
        {
          image: require("~/assets/img/history/trade-in.png"),
          text: "Trade-In",
          stories: [
            {
              title: "выгода до 300 000 ₽ за обмен автомобиля по Trade-in",
              description: "Сядьте за руль автомобиля своей мечты уже сегодня",
              image: require("~/assets/img/history/main/his-6.webp"),
              link: "/trade-in"
            },
            {
              title: "рыночная оценка и оформление за 30 минут",
              description:
                "Быстро оценим ваш автомобиль и представим скидку на новый",
              image: require("~/assets/img/history/main/his-7.webp"),
              link: "/trade-in"
            }
          ]
        },
        {
          image: require("~/assets/img/history/test-drive.png"),
          text: "Тест-драйв",
          stories: [
            {
              title: "запишитесь на тест-драйв",
              description: "Весь модельный ряд в наличии на тест-драйве! Запишитесь сейчас и получите бонус при покупке",
              image: require("~/assets/img/history/main/his-5.webp"),
              link: "/test-drive"
            }
          ]
        },
        {
          image: require("~/assets/img/history/installment.png"),
          text: "Рассрочка",
          stories: [
            {
              title: "0% на все автомобили",
              description:
                "Рассрочка доступна на все авто, представленные на нашем сайте",
              image: require("~/assets/img/history/main/his-7.webp"),
              link: "/installment"
            },
            {
              title: "платим проценты за вас",
              description: "Оформите автомобиль в рассрочку без процентов",
              image: require("~/assets/img/history/main/his-2.webp"),
              link: "/installment"
            }
          ]
        },
        {
          image: require("~/assets/img/history/buyout.png"),
          text: "Выкуп",
          stories: [
            {
              title: "Получите деньги в день обращения",
              description:
                "Запишитесь на бесплатный осмотр, чтобы получить финальную цену за автомобиль",
              image: require("~/assets/img/history/main/his-4.webp"),
              link: "/buyout"
            },
            // {
            //   title: "среднерыночная оценка",
            //   description: "Дадим столько же, без поиска и встреч с клиентами",
            //   image: require("~/assets/img/history/main/his-1.webp"),
            //   link: "/buyout"
            // }
          ]
        },
        {
          image: require("~/assets/img/history/selection.png"),
          text: "Подбор",
          stories: [
            {
              title: "Бесплатно подберем автомобиль",
              description:
                "Наши менеджеры предложат варианты по вашим пожеланиям и бюджету",
              image: require("~/assets/img/history/main/his-8.webp"),
              link: "/selector-car"
            }
          ]
        },
        {
          image: require("~/assets/img/history/promotions.png"),
          text: "Акции",
          stories: [
            // {
            //   title: "Уже есть предложение?",
            //   description: "Улучшим любое предложение конкурентов",
            //   image: require("~/assets/img/history/main/his-1.webp"),
            //   link: "/services"
            // },
            {
              title: "Счастливые часы в АВтоцентр первый",
              description:
                "Дополнительная скидка 40 000₽ каждому клиенту, при визите нашего автосалона до 12:00",
              image: require("~/assets/img/history/main/his-4.webp"),
              link: "/services"
            },
            {
              title: "При покупке авто в кредит",
              description:
                "Получите дополнительные подарки: КАСКО и 2-ой комплект резины",
              image: require("~/assets/img/history/main/his-8.webp"),
              link: "/services"
            },
            {
              title: "Дорога за наш счет",
              description:
                "компенсируем затраты на билеты и такси при покупке автомобиля",
              image: require("~/assets/img/history/main/his-7.webp"),
              link: "/services"
            }
          ]
        }
      ]
    };
  },
  computed: {
    slidesPerView() {
      if (window.innerWidth >= 1600) return 7;
      if (window.innerWidth >= 1440) return 5;
      if (window.innerWidth >= 768) return 3;
      return 1;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper();
    });
    window.addEventListener("resize", this.updateSwiper);
  },
  methods: {
    async initSwiper() {
      this.swiper = new swiper.default(".history-list__slider", {
        modules: [swiper.Pagination, swiper.Autoplay],
        slidesPerView: this.slidesPerView,
        spaceBetween: 12,
        loop: window.innerWidth <= 1440,
        autoplay:
          window.innerWidth <= 1440
            ? {
                delay: 8000,
                disableOnInteraction: false
              }
            : false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
            spaceBetween: 12
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 12
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 12
          },
          1700: {
            slidesPerView: 7,
            spaceBetween: 12
          }
        }
      });
    },
    updateSwiper() {
      if (this.swiper) {
        this.swiper.destroy();
      }
      this.initSwiper();
    },
    openModal(item) {
      this.selectedItem = item;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedItem = null;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateSwiper);
    if (this.swiper) {
      this.swiper.destroy();
    }
  }
};
