
import { mapGetters } from "vuex";

import settings from "../mixins/settings";

export default {
  props: ["error"],
  head: {
    title: "Ошибка 404"
  },

  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    })
  }
};
