
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isMoscow: "isMoscow"
      // showComm: 'showComm',
    }),
    siteList() {
      return !this.$device.isMobile
        ? [
            // {
            // 	name: 'Новые авто',
            // 	link: '/new',
            // 	show: true
            //
            // },
            {
              name: "Каталог",
              link: "/cars",
              show: true
            },
            {
              name: "Автокредит",
              link: "/credit",
              show: true
            },
            {
              name: "Trade-In",
              link: "/trade-in",
              show: true
            },
            {
              name: "Рассрочка",
              link: "/installment",
              show: true
            },
            {
              name: "Выкуп",
              link: "/buyout",
              show: true
            },
            {
              name: "Тест-драйв",
              link: "/test-drive",
              show: true
            },
            {
              name: "Подбор авто",
              link: "/selector-car",
              show: true
            },
            {
              name: "Банки-партнеры",
              link: "/banks",
              show: true
            },
            {
              name: "Контакты",
              link: "/contacts",
              show: true
            },
            {
              name: "Об автосалоне",
              link: "/about",
              show: true
            },
            {
              name: "Услуги",
              link: "/services",
              show: true
            }
          ]
        : [
            // {
            // 	name: 'Новые авто',
            // 	link: '/new',
            // 	show: true
            // },
            {
              name: "Каталог",
              link: "/cars",
              show: true
            },
            {
              name: "Автокредит",
              link: "/credit",
              show: true
            },
            {
              name: "Trade-In",
              link: "/trade-in",
              show: true
            },
            {
              name: "Рассрочка",
              link: "/installment",
              show: true
            },
            {
              name: "Выкуп",
              link: "/buyout",
              show: true
            },
            {
              name: "Тест-драйв",
              link: "/test-drive",
              show: true
            },
            {
              name: "Подбор авто",
              link: "/selector-car",
              show: true
            },
            {
              name: "Банки-партнеры",
              link: "/banks",
              show: true
            },
            {
              name: "Контакты",
              link: "/contacts",
              show: true
            },
            {
              name: "Об автосалоне",
              link: "/about",
              show: true
            },
            {
              name: "Услуги",
              link: "/services",
              show: true
            }

            // {
            // 	name: 'Избранное',
            // 	link: '/favorites',
            // 	show: true
            // },
          ];
    },
    siteList_sub() {
      return [
        // {
        // 	name: 'Отзывы',
        // 	link: '/reviews',
        // 	show: true
        // },
        {
          name: "Контакты",
          link: "/contacts",
          show: true
        },
        {
          name: "Об автоцентре",
          link: "/about",
          show: true
        }
      ];
    }
  }
};
